import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Button, Icon, Dropdown } from '@upperhand/playmaker';

import ClockIcon from 'shared/components/icons/Clock4pm.jsx';
import StaffAutocomplete from 'calendar/components/staff_unavailability/StaffAutocomplete.jsx';

import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

import StaffUnavailabilityActions from 'calendar/actions/StaffUnavailabilityActions';

import './styles.scss';

const blockReasons = [
  'appointment',
  'bereavement',
  'busy',
  'coaching_commitment',
  'emergency',
  'sick',
  'vacation',
  'other',
];

const timeRangeText = dateRange => {
  const formattedStart = formattedHourString(dateRange.start, {
    trailingZeros: true,
    truncateMeridiem: false,
  });
  const formattedEnd = formattedHourString(dateRange.end, {
    trailingZeros: true,
    truncateMeridiem: false,
  });

  return `${formattedStart}    -    ${formattedEnd}`;
};

function StaffUnavailability({
  actions = StaffUnavailabilityActions,
  staffUnavailabilityStore = {},
  staffStore = {},
}) {
  const intl = useIntl();
  const { dateRange, staffIds, reason, selectedStaffId, isCreating } =
    staffUnavailabilityStore;
  const { allStaffMap } = staffStore;

  const handleFieldChange = (field, value) => {
    actions.handleChange({ field, value });
  };

  return (
    <div className="staff-unavailability">
      <div className="staff-unavailability__header">
        <Typography variant="h6">
          {t('.add_staff_unavailability', intl, __filenamespace)}
        </Typography>
        <Button
          rounded
          classes={{ root: 'staff-unavailability__close-btn' }}
          type="tertiary"
          onClick={actions.cancelBlockTime}
        >
          <Icon name="close" />
        </Button>
      </div>
      <div className="staff-unavailability__content">
        <div className="staff-unavailability__time-container">
          <div className="staff-unavailability__time">
            <ClockIcon />
            <Typography variant="body1">{timeRangeText(dateRange)}</Typography>
          </div>
          <div className="staff-unavailability__date">
            <Icon name="calendar" />
            <Typography variant="body1">
              {dateRange?.start?.format('MMM D, YYYY')}
            </Typography>
          </div>
        </div>
        <div className="staff-unavailability__field">
          <Typography variant="fieldLabel">
            {t('.staff', intl, __filenamespace)}
          </Typography>
          <StaffAutocomplete
            selectedStaffId={selectedStaffId}
            staffItems={staffIds
              .map(id => ({
                label: allStaffMap.get(id).name(),
                value: id,
              }))
              .toArray()}
            onChange={value => handleFieldChange('selectedStaffId', value)}
          />
        </div>
        <div className="staff-unavailability__field">
          <Typography variant="fieldLabel">
            {t('.reason', intl, __filenamespace)}
          </Typography>
          <Dropdown
            fullWidth
            classes={{
              root: 'staff-unavailability__dropdown',
              selectedItem: 'staff-unavailability__dropdown-selected',
            }}
            name="reason"
            value={reason}
            items={blockReasons.map(r => ({
              label: r.replace('_', ' '),
              value: r,
            }))}
            onChange={e => handleFieldChange('reason', e.target.value)}
          />
        </div>
      </div>
      <div className="staff-unavailability__footer">
        <Button
          type="tertiary"
          onClick={actions.cancelBlockTime}
          classes={{ root: 'staff-unavailability__cancel-btn' }}
        >
          {t('.cancel', intl, __filenamespace)}
        </Button>
        <Button
          disabled={!selectedStaffId || !reason || isCreating}
          type="primary"
          onClick={actions.create}
          classes={{ root: 'staff-unavailability__block-btn' }}
        >
          {t('.add_to_schedule', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

StaffUnavailability.propTypes = {
  actions: PropTypes.object,
  staffStore: PropTypes.object,
  staffUnavailabilityStore: PropTypes.object,
};

export default memo(StaffUnavailability);
