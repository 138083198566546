import { Set, OrderedSet } from 'immutable';
import moment from 'moment-timezone';
import debounce from 'lodash.debounce';

import { currentUser } from 'shared/utils/UserUtils.jsx';

import {
  CreditCountSource,
  SessionSource,
  StaffSource,
  RegistrationSource,
  LocationSource,
  CreditPassSource,
  EventSource,
  EventTypeSource,
  MembershipSource,
} from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import Actions from './Actions';
import { FILTER_STAFF_ALL } from './components/StaffFilter.jsx';

export const STEPS = {
  SESSION_CHOOSE: 'session_choose',
  CREDIT_PASS_CHOOSE: 'credit_pass_choose',
  SESSION_BOOK_SUCCESS: 'session_book_success',
};

class SessionSchedulingStore extends UpperHandStore {
  constructor() {
    super();

    this.debouncedSessionList = debounce(this.listSessions, 300);
    this.reset();
    this.bindListeners({
      bookSessions: Actions.bookSessions,
      bookSessionError: Actions.bookSessionError,
      bookSessionSuccess: Actions.bookSessionSuccess,
      creditCountListSuccess: Actions.creditCountListSuccess,
      creditCountListError: Actions.creditCountListError,
      filterStaff: Actions.filterStaff,
      mounted: Actions.mounted,
      sessionsLoadMore: Actions.sessionsLoadMore,
      sessionListSuccess: Actions.sessionListSuccess,
      sessionListError: Actions.sessionListError,
      staffListSuccess: Actions.staffListSuccess,
      staffListError: Actions.staffListError,
      locationListSuccess: Actions.locationListSuccess,
      locationListError: Actions.locationListError,
      selectSession: Actions.selectSession,
      unmount: Actions.unmount,
      updateSelectedDayOfWeek: Actions.updateSelectedDayOfWeek,
      updateSelectedLocations: Actions.updateSelectedLocations,
      setStep: Actions.setStep,
      listCreditPasses: Actions.listCreditPasses,
      listCreditPassesSuccess: Actions.listCreditPassesSuccess,
      listCreditPassesError: Actions.listCreditPassesError,
      selectCreditPass: Actions.selectCreditPass,
      addCreditPassToCart: Actions.addCreditPassToCart,
      listMemberships: Actions.listMemberships,
      listMembershipsSuccess: Actions.listMembershipsSuccess,
      listMembershipsError: Actions.listMembershipsError,
    });
  }

  reset() {
    this.clientId = null;
    this.eventId = null;
    this.allowPast = false;
    this.step = STEPS.SESSION_CHOOSE;
    this.onNext = () => {};
    this.onNextAddToCart = () => {};

    this.creditCountsLoading = true;
    this.sessionsLoading = true;
    this.staffLoading = true;
    this.locationsLoading = true;
    this.bookingProcessing = false;
    this.addToCartProcessing = 0;

    this.creditsAvailable = 0;
    this.page = 1;
    this.perPage = 50;
    this.selectedSessionIds = Set();
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.staffIds = Set();
    this.staffFilter = FILTER_STAFF_ALL;

    this.selectedDayOfWeek = Set([-1, 0, 1, 2, 3, 4, 5, 6]);

    this.locationIds = Set();
    this.selectedLocations = Set();

    this.creditPassesIds = OrderedSet();
    this.selectedCreditPassIds = OrderedSet();
    this.cpEventIds = Set();
    this.cpEventTypeIds = Set();
    this.creditPassesLoading = true;
    this.hasMemberships = false;
  }

  updateSelectedDayOfWeek({ selectedDay, isChecked }) {
    if (selectedDay === -1 && isChecked) {
      this.selectedDayOfWeek = new Set([-1, 0, 1, 2, 3, 4, 5, 6]);
    } else if (isChecked) {
      this.selectedDayOfWeek = this.selectedDayOfWeek.add(selectedDay);
    } else if (selectedDay === -1 && !isChecked) {
      this.selectedDayOfWeek = new Set();
    } else {
      this.selectedDayOfWeek = this.selectedDayOfWeek.delete(selectedDay);
      if (this.selectedDayOfWeek.includes(-1)) {
        this.selectedDayOfWeek = this.selectedDayOfWeek.delete(-1);
      }
    }
    if (this.selectedDayOfWeek.size === 7) {
      this.selectedDayOfWeek = this.selectedDayOfWeek.add(-1);
    }
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.sessionsLoading = true;
    this.debouncedSessionList();
  }

  updateSelectedLocations([selectedLocations, _allSelected]) {
    this.selectedLocations = Set(selectedLocations);
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.sessionsLoading = true;
    this.debouncedSessionList();
  }

  mounted({
    clientId,
    eventId,
    allowPast = false,
    onNext = () => {},
    onBack = () => {},
    onNextAddToCart = () => {},
    selectedSessionIds = Set(),
  }) {
    this.reset();

    this.clientId = clientId;
    this.eventId = eventId;
    this.allowPast = allowPast;
    this.onNext = onNext;
    this.onBack = onBack;
    this.onNextAddToCart = onNextAddToCart;
    this.selectedSessionIds = selectedSessionIds;

    this.listSessions();
    this.listStaff();
    this.listLocations();
    this.listCreditCounts();
  }

  unmount() {
    this.reset();
  }

  setStep(step = STEPS.SESSION_CHOOSE) {
    this.step = step;
  }

  listSessions() {
    const startTime =
      this.allowPast && currentUser().isStaff()
        ? moment().subtract(1, 'week')
        : moment();

    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      location_ids: this.selectedLocations.toArray(),
      start_time: startTime.toISOString(),
      skip_deadlined: currentUser().isClient(),
    };

    if (this.staffFilter !== FILTER_STAFF_ALL) {
      params.staff_ids = [this.staffFilter];
    }

    if (this.selectedDayOfWeek && this.selectedDayOfWeek.size > 0) {
      params.days_of_week = this.selectedDayOfWeek.toArray();
    }

    this.sessionsLoading = true;

    SessionSource.list({
      params,
      success: Actions.sessionListSuccess,
      error: Actions.sessionListError,
    });
  }

  listStaff() {
    const params = {
      event_ids: this.eventId,
    };

    this.staffLoading = true;

    StaffSource.list({
      params,
      success: Actions.staffListSuccess,
      error: Actions.staffListError,
    });
  }

  listLocations() {
    const params = {
      event_ids: [this.eventId],
      per_page: 50,
    };

    this.locationsLoading = true;

    LocationSource.list({
      params,
      success: Actions.locationListSuccess,
      error: Actions.locationListError,
    });
  }

  locationListSuccess({ locations }) {
    this.locationsLoading = false;
    this.locationIds = Set(locations.map(l => l.id));
    this.selectedLocations = this.locationIds;
  }

  locationListError() {
    this.locationsLoading = false;
  }

  sessionListSuccess({ sessions, totalCount }) {
    this.sessionIds = this.sessionIds.concat(sessions.map(s => s.id));
    sessions.forEach(s => {
      if (
        (this.selectedSessionIds.includes(s.id) && s.spots_remaining === 0) ||
        s.client_ids.includes(this.clientId)
      ) {
        this.selectedSessionIds = this.selectedSessionIds.delete(s.id);
      }
    });

    this.sessionsLoading = false;
    this.sessionsHasMore = this.sessionIds.size < totalCount;
  }

  sessionListError() {
    this.sessionsLoading = false;
  }

  staffListSuccess({ staff }) {
    this.staffIds = staff.map(c => c.id);
    this.staffLoading = false;
  }

  staffListError() {
    this.staffLoading = false;
  }

  sessionsLoadMore() {
    this.page += 1;

    this.listSessions();
  }

  filterStaff(staffId) {
    this.staffFilter = staffId;
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.sessionsLoading = true;
    this.eventsLoading = true;
    this.eventTypesLoading = true;

    this.debouncedSessionList();
  }

  selectSession(id) {
    if (this.selectedSessionIds.includes(id)) {
      this.selectedSessionIds = this.selectedSessionIds.delete(id);
    } else if (
      this.selectedSessionIds.size < this.creditsAvailable &&
      this.creditsAvailable > 0
    ) {
      this.selectedSessionIds = this.selectedSessionIds.add(id);
    } else if (this.creditsAvailable === 0) {
      this.selectedSessionIds = this.selectedSessionIds.add(id);
    }
  }

  listCreditCounts() {
    this.creditCountsLoading = true;

    CreditCountSource.list({
      params: {
        client_ids: [this.clientId],
        event_ids: [this.eventId],
        per_page: 100,
      },
      success: Actions.creditCountListSuccess,
      error: Actions.creditCountListError,
    });
  }

  creditCountListSuccess({ credit_counts: creditCounts }) {
    this.creditCountsLoading = false;
    const creditCount = creditCounts.first();
    this.creditsAvailable = creditCount
      ? creditCount.availableCreditCount()
      : 0;
  }

  creditCountListError() {
    this.creditCountsLoading = false;
  }

  listAdditionalInfo() {
    if (this.cpEventIds.size > 0) {
      this.eventsLoading = true;
      EventSource.list({
        params: {
          ids: this.cpEventIds.toArray(),
          per_page: 50,
        },
        success: () => {
          this.eventsLoading = false;
        },
        error: () => {
          this.eventsLoading = false;
        },
      });
    }

    if (this.cpEventTypeIds.size > 0) {
      EventTypeSource.list({
        params: {
          ids: this.cpEventTypeIds.toArray(),
          per_page: 50,
        },
        success: () => {
          this.eventTypesLoading = false;
        },
        error: () => {
          this.eventTypesLoading = false;
        },
      });
    }
  }

  listMemberships() {
    this.creditPassesLoading = true;
    MembershipSource.list({
      params: {
        event_ids: [this.eventId],
        // Need only one membership to show view memberships button
        per_page: 1,
      },
      success: Actions.listMembershipsSuccess,
      error: Actions.listMembershipsError,
    });
  }

  listMembershipsSuccess({ memberships }) {
    this.creditPassesLoading = false;
    this.hasMemberships = memberships.size > 0;
  }

  listMembershipsError(...args) {
    this.notifyError('error memberships listing', args);
    this.creditPassesLoading = false;
  }

  listCreditPasses(page = 1) {
    this.creditPassesLoading = true;
    CreditPassSource.list({
      params: {
        page,
        event_ids: [this.eventId],
        per_page: 50,
      },
      success: Actions.listCreditPassesSuccess,
      error: Actions.listCreditPassesError,
    });
  }

  listCreditPassesSuccess({
    credit_passes: creditPasses,
    page,
    perPage,
    totalCount,
  }) {
    if (creditPasses.size === 0) {
      this.listMemberships();
      return;
    }

    this.creditPassesIds = this.creditPassesIds.concat(
      creditPasses.map(cp => cp.id)
    );
    this.cpEventIds = this.cpEventIds.concat(
      creditPasses.flatMap(cp =>
        cp.credit_pass_credits.flatMap(cpc => cpc.event_ids)
      )
    );

    this.cpEventTypeIds = this.cpEventTypeIds.concat(
      creditPasses.flatMap(cp =>
        cp.credit_pass_credits.flatMap(cpc => cpc.event_type_ids)
      )
    );

    if (page * perPage < totalCount) {
      this.listCreditPasses(page + 1);
    } else {
      this.listAdditionalInfo();
      this.creditPassesLoading = false;
    }
  }

  listCreditPassesError(...args) {
    this.notifyError('error listing credit passes', args);
  }

  selectCreditPass(id) {
    if (this.selectedCreditPassIds.includes(id)) {
      this.selectedCreditPassIds = this.selectedCreditPassIds.delete(id);
    } else {
      this.selectedCreditPassIds = this.selectedCreditPassIds.add(id);
    }
  }

  bookSessions() {
    this.bookingProcessing = true;

    const schedules = this.selectedSessionIds
      .map(sId => {
        const request = new RegistrationSource.Schedule(this.clientId);
        request.bySession(sId);

        return request.toServer();
      })
      .toArray();

    RegistrationSource.schedule({
      schedules,
      success: Actions.bookSessionSuccess,
      error: Actions.bookSessionError,
    });
  }

  bookSessionError(...args) {
    this.bookingProcessing = false;
    this.notifyError('error session booking', args);
  }

  bookSessionSuccess() {
    this.bookingProcessing = false;
    this.step = STEPS.SESSION_BOOK_SUCCESS;

    this.onNext();
  }

  addCreditPassToCart() {
    this.onNextAddToCart({
      selectedCreditPasses: this.selectedCreditPassIds,
      selectedSessions: this.selectedSessionIds,
      clientId: this.clientId,
      eventId: this.eventId,
    });
  }
}

export default alt.createStore(
  SessionSchedulingStore,
  'SessionSchedulingStore'
);
