import moment from 'moment-timezone';
import { List, OrderedSet, OrderedMap } from 'immutable';

import * as DateUtils from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import EventTime, { EVENT_TIME_TYPES } from 'calendar/records/EventTime.jsx';

import { StaffUnavailabilitySource } from 'sources';

import StaffUnavailabilityActions from 'calendar/actions/StaffUnavailabilityActions';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import CalendarStore from 'calendar/stores/CalendarStore.jsx';
import QuickScheduleStore from 'calendar/stores/QuickScheduleStore.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';

class StaffUnavailabilityStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      handleChange: StaffUnavailabilityActions.handleChange,
      toggleRemoveDialog: StaffUnavailabilityActions.toggleRemoveDialog,
      blockTime: StaffUnavailabilityActions.blockTime,
      cancelBlockTime: StaffUnavailabilityActions.cancelBlockTime,
      changeCalendarDate: CalendarActions.setCalendarDate,
      listStaffSuccess: StaffActions.listSuccess,

      create: StaffUnavailabilityActions.create,
      createSuccess: StaffUnavailabilityActions.createSuccess,
      createError: StaffUnavailabilityActions.createError,

      update: StaffUnavailabilityActions.update,
      updateSuccess: StaffUnavailabilityActions.updateSuccess,
      updateError: StaffUnavailabilityActions.updateError,

      delete: StaffUnavailabilityActions.delete,
      deleteSuccess: StaffUnavailabilityActions.deleteSuccess,
      deleteError: StaffUnavailabilityActions.deleteError,

      list: StaffUnavailabilityActions.list,
      listSuccess: StaffUnavailabilityActions.listSuccess,
      listError: StaffUnavailabilityActions.listError,

      onCalendarListSuccess: CalendarActions.listSuccess,
    });
  }

  reset() {
    this.isLoading = false;
    this.isUpdating = false;
    this.isCreating = false;
    this.isDeleting = false;

    this.staffUnavailabilityIds = OrderedSet();
    this.blockedEventTimes = List();
    this.blockedEventTimesByWeekDay = OrderedMap();

    this.isBlockingTime = false;
    this.staffIds = OrderedSet();
    this.unavailabilityIdToRemove = null;

    this.dateRange = null;
    this.selectedStaffId = null;
    this.reason = '';
  }

  onCalendarListSuccess() {
    this.list();
  }

  changeCalendarDate() {
    this.blockedEventTimes = List();
    this.blockedEventTimesByWeekDay = OrderedMap();
  }

  toggleRemoveDialog(id) {
    this.unavailabilityIdToRemove = id;
  }

  blockTime() {
    this.waitFor(QuickScheduleStore);

    const { dateRange } = QuickScheduleStore.getState();

    this.isBlockingTime = true;
    this.dateRange = dateRange;
  }

  cancelBlockTime() {
    this.isBlockingTime = false;
    this.dateRange = null;
    this.selectedStaffId = null;
    this.reason = '';
  }

  handleChange({ field, value }) {
    this[field] = value;
  }

  listStaffSuccess([{ staff }]) {
    this.waitFor(StaffStore);
    this.staffIds = this.staffIds.concat(staff.map(s => s.id));
  }

  create() {
    this.isCreating = true;

    StaffUnavailabilitySource.create({
      recordAttributes: {
        starts_at: this.dateRange.start.format(),
        ends_at: this.dateRange.end.format(),
        staff_id: this.selectedStaffId,
        reason: this.reason,
      },
      success: StaffUnavailabilityActions.createSuccess,
      error: StaffUnavailabilityActions.createError,
    });
  }

  createSuccess(unavailability) {
    this.isCreating = false;
    this.blockedEventTimes = this.blockedEventTimes.push(
      new EventTime({
        start_time: unavailability.starts_at
          .clone()
          .tz(DateUtils.customerTZ())
          .format('HH:mm'),
        end_time: unavailability.ends_at
          .clone()
          .tz(DateUtils.customerTZ())
          .format('HH:mm'),
        event_date: unavailability.starts_at
          .clone()
          .tz(DateUtils.customerTZ())
          .format('YYYY-MM-DD'),
        staff_ids: [unavailability.staff.id],
        unavailabilityId: unavailability.id,
        unavailabilityReason: unavailability.reason,
        type: EVENT_TIME_TYPES.STAFF_UNAVAILABILITY,
      })
    );

    const blockedEventTimesByWeekDay = this.blockedEventTimes.groupBy(
      eventTime => eventTime.dateRange().start.format('YYYY-MM-DD')
    );

    this.blockedEventTimesByWeekDay = this.blockedEventTimesByWeekDay.mergeWith(
      (oldList = List(), newList = List()) => oldList.concat(newList),
      blockedEventTimesByWeekDay
    );

    this.cancelBlockTime();

    QuickScheduleActions.close.defer();
    MessageWindowActions.addMessage.defer('Staff unavailability added');
  }

  createError(...args) {
    this.isCreating = false;
    this.notifyError('Failed to create staff unavailability', ...args);
  }

  update() {
    this.isUpdating = true;
  }

  updateSuccess() {
    this.isUpdating = false;
  }

  updateError() {
    this.isUpdating = false;
  }

  delete() {
    this.isDeleting = true;

    StaffUnavailabilitySource.remove({
      id: this.unavailabilityIdToRemove,
      success: {
        action: StaffUnavailabilityActions.deleteSuccess,
        args: [this.unavailabilityIdToRemove],
      },
      error: StaffUnavailabilityActions.deleteError,
    });
  }

  deleteSuccess([_, id]) {
    this.isDeleting = false;
    this.unavailabilityIdToRemove = null;
    this.blockedEventTimes = this.blockedEventTimes.filter(
      e => e.unavailabilityId !== id
    );
    this.blockedEventTimesByWeekDay = this.blockedEventTimesByWeekDay.map(
      list => list.filter(e => e.unavailabilityId !== id)
    );

    MessageWindowActions.addMessage.defer('Staff unavailability removed');
  }

  deleteError() {
    this.isDeleting = false;
  }

  list(page = 1) {
    this.isLoading = true;
    this.waitFor(CalendarStore);

    const { date, view } = CalendarStore.getState();

    let dateRange = {
      start: moment(date).startOf('day'),
      end: moment(date).endOf('day'),
    };

    if (view === 'week') {
      dateRange = DateUtils.weekRangeFromDate(date);
    }

    StaffUnavailabilitySource.list({
      params: {
        page,
        per_page: 50,
        start_time: dateRange.start,
        end_time: dateRange.end,
      },
      success: StaffUnavailabilityActions.listSuccess,
      error: StaffUnavailabilityActions.listError,
    });
  }

  listSuccess({
    staff_unavailabilities: unavailabilities,
    page,
    perPage,
    totalCount,
  }) {
    const blockedEventTimes = unavailabilities.map(
      u =>
        new EventTime({
          start_time: u.starts_at
            .clone()
            .tz(DateUtils.customerTZ())
            .format('HH:mm'),
          end_time: u.ends_at
            .clone()
            .tz(DateUtils.customerTZ())
            .format('HH:mm'),
          event_date: u.starts_at
            .clone()
            .tz(DateUtils.customerTZ())
            .format('YYYY-MM-DD'),
          staff_ids: [u.staff.id],
          unavailabilityId: u.id,
          unavailabilityReason: u.reason,
          type: EVENT_TIME_TYPES.STAFF_UNAVAILABILITY,
        })
    );

    const blockedEventTimesByWeekDay = blockedEventTimes.groupBy(eventTime =>
      eventTime.dateRange().start.format('YYYY-MM-DD')
    );

    if (page === 1) {
      this.blockedEventTimes = List();
      this.blockedEventTimesByWeekDay = OrderedMap();
    }

    this.blockedEventTimes = this.blockedEventTimes.concat(blockedEventTimes);
    this.blockedEventTimesByWeekDay = this.blockedEventTimesByWeekDay.mergeWith(
      (oldList = List(), newList = List()) => oldList.concat(newList),
      blockedEventTimesByWeekDay
    );

    if (page * perPage < totalCount) {
      this.list(page + 1);
    } else {
      this.isLoading = false;
    }
  }

  listError() {
    this.isLoading = false;
  }
}

export default alt.createStore(
  StaffUnavailabilityStore,
  'StaffUnavailabilityStore'
);
