import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import { Set } from 'immutable';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import AvatarWithName from 'shared/components/AvatarWithName.jsx';

import ClearIcon from '@mui/icons-material/Clear';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import PurchaseDrawerContainer from 'shared/components/PurchaseDrawerContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import WhenSignedIn from 'shared/components/WhenSignedIn.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';

import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { removeHash, shouldOpenPurchaseDrawer } from 'shared/utils/RouteUtils';

const PURCHASE_DRAWER_REGEX =
  /pdo([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;

const styles = {
  container: {
    lineHeight: '24px',
  },
  creditPassTitle: {
    fontWeight: 600,
    fontFamily: 'benton-sans-condensed, sans-serif',
    textTransform: 'capitalize',
    fontSize: 18,
    paddingBottom: 7,
  },
  purchaseTarget: {
    fontWeight: 600,
    fontSize: 15,
  },
  cancelButton: {
    margin: '1rem 0',
    height: 50,
  },
  avatar: {
    marginRight: '1rem',
  },
  pricingInfo: {
    marginBottom: 10,
  },
  benefitsContainer: {
    backgroundColor: uhColors.lightYellow,
    marginBottom: 20,
    marginTop: 20,
  },
  benefits: {
    padding: '12px 12px 14px 18px',
  },
  bull: {
    lineHeight: 0,
    fontSize: 30,
  },
  creditPassExpiration: {
    display: 'block',
    marginTop: 10,
    fontSize: 15,
    fontStyle: 'italic',
  },
  purchasedComponent: {
    color: uhColors.darkRed,
    textAlign: 'right',
  },
  profileCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0 5px 10px',
  },
  selectedProfilesList: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};

function PricingInfo({ price }) {
  return (
    <div style={styles.pricingInfo}>
      <div style={boldText}>
        <span style={styles.bull}>&bull;</span>
        &nbsp;
        {price === 0 ? (
          <FormattedMessage id={messageId('.free', __filenamespace)} />
        ) : (
          <FormattedCurrency value={price} fromCents />
        )}
      </div>
    </div>
  );
}

function Benefits({ creditPass, eventTypes, events }) {
  return (
    <div style={styles.benefitsContainer}>
      <div style={styles.benefits}>
        <div style={boldText}>
          <FormattedMessage id={messageId('.benefits', __filenamespace)} />
        </div>
        <CreditPassCredits
          creditPass={creditPass}
          eventTypes={eventTypes}
          events={events}
        />
        <CreditPassExpiration
          creditPassId={creditPass.id}
          style={styles.creditPassExpiration}
        />
      </div>
    </div>
  );
}

function ProfileCard({ profile, onRemove }) {
  if (!profile) return null;

  return (
    <Paper style={styles.profileCard} key={profile.id}>
      <AvatarWithName user={profile} />
      <IconButton
        iconStyle={styles.icon}
        onClick={() => onRemove(profile.id)}
        style={styles.button}
      >
        <ClearIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
    </Paper>
  );
}

class PurchaseDrawer extends React.Component {
  componentDidMount() {
    const {
      creditPassListingStore: { records },
    } = this.props;
    if (
      /credit_passes$/.test(window.location.pathname) &&
      shouldOpenPurchaseDrawer()
    ) {
      if (records.size > 0) {
        const creditPassId = window.location.hash.match(
          PURCHASE_DRAWER_REGEX
        )[1];
        removeHash();
        CreditPassPurchasingActions.purchaseClicked.defer({
          creditPass: records.filter(pass => pass.id === creditPassId).first(),
          buyerId: currentUser().customer_user_id,
        });
      }
    }
  }

  render() {
    const {
      intl,
      cartStore,
      close,
      creditPassSpecificEventsListStore: { events },
      drawer,
      eventTypeListingStore: { eventTypes },
      creditPassPurchasingStore: {
        allProfiles,
        availableProfiles,
        creditPass,
        orderItem,
        creditPassPackage,
        isLoading: creditPassLoading,
      },
      title,
    } = this.props;

    const containerProps = {
      close,
      open: drawer.activeDrawer === 'CreditPassPurchaseDrawer',
      style: styles.container,
      title,
    };

    const clientIds = creditPassPackage.get('client_ids', Set()).toJS();

    const addToCart = () =>
      CartActions.creditPassPackageAdded(creditPassPackage);
    const updateCart = () =>
      CartActions.update(orderItem.id, creditPassPackage);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <PurchaseDrawerContainer {...containerProps}>
        <div style={styles.creditPassTitle}>{creditPass.name}</div>
        <PricingInfo price={creditPass.price} />
        <Typography variant="subtitle2">{creditPass.description}</Typography>
        {!!creditPass.credit_pass_credits.size && (
          <Benefits
            creditPass={creditPass}
            eventTypes={eventTypes}
            events={events}
          />
        )}
        <WhenSignedIn
          notSignedIn={
            <SignInPrompt
              redirectTo={creditPass.url({ relative: true })}
              redirectHash={`%23pdo${creditPass.id}`}
            />
          }
        >
          <SpinWhileLoading isLoading={creditPassLoading}>
            <ProfileSelector
              preventProfileCreation
              hintText={t('.choose_profile', intl, __filenamespace)}
              header={t('.for_whom', intl, __filenamespace)}
              headerStyle={styles.purchaseTarget}
              athletes={availableProfiles}
              value={null}
              afterProfileCreate={() => null}
              onChange={(_, __, id) =>
                CreditPassPurchasingActions.profileAdded(id)
              }
            />
            <div style={styles.selectedProfilesList}>
              {clientIds.map(id => (
                <ProfileCard
                  key={id}
                  profile={allProfiles.get(id)}
                  onRemove={clientId =>
                    CreditPassPurchasingActions.profileAdded(clientId)
                  }
                />
              ))}
            </div>
            <CartCommitButton
              addToCartLabel={
                creditPass.price === 0 ? t('actions.enroll', intl) : null
              }
              handleAddToCartClick={addToCart}
              handleUpdateCartClick={updateCart}
              hasOrderItemId={orderItem}
              hasUpdate={orderItem}
              itemIsValid={creditPassPackage.client_ids.size > 0}
              isNavigatingToCart={cartStore.isNavigatingToCart}
              isUpdatingCart={cartStore.isUpdatingCart}
            />
            <Button
              fullWidth
              color="default"
              variant="contained"
              style={styles.cancelButton}
              onClick={close}
            >
              {t('actions.cancel', intl)}
            </Button>
          </SpinWhileLoading>
        </WhenSignedIn>
      </PurchaseDrawerContainer>
    );
  }
}

export default injectIntl(PurchaseDrawer);
