export default alt.generateActions(
  'fetch',
  'fetchSuccess',
  'fetchError',
  'fetchCartCreditPassesSuccess',
  'fetchCartCreditPassesError',
  'addSuccess',
  'addSuccessNoRedirect',
  'addError',
  'updateSuccess',
  'updateSuccessNoRedirect',
  'updateError',
  'itemRemoved',
  'removeSuccess',
  'removeError',
  'itemEditSelected',
  'itemViewSelected',
  'addEventClicked',
  'cancelCartClicked',
  'continueToPaymentClicked',
  'couponApplied',
  'applyCouponSuccess',
  'applyCouponError',
  'manageDiscount',
  'manageDiscountSuccess',
  'manageDiscountError',
  'taxExemptToggled',
  'feeExemptToggled',
  'updateTaxExemptSuccess',
  'updateTaxExemptError',
  'updateFeeExemptSuccess',
  'updateFeeExemptError',
  'taxIdUpdated',
  'updateTaxIdSuccess',
  'updateTaxIdError',
  'updateManualDiscount',
  'proRateUpdate',
  'updateProRateSuccess',
  'updateProRateError',
  'fetchSessionsSuccess',
  'fetchSessionsError',
  'fetchStaffSuccess',
  'fetchStaffError',
  'updateAccountCredits',
  'applyAccountCredits',
  'applyAccountCreditsSuccess',
  'applyAccountCreditsError',
  'scheduleSessionSuccess',
  'scheduleSessionError',
  'addClassSchedule'
);
