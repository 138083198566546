import * as React from 'react';
import Barcode from 'react-barcode';

import { formatCurrency } from 'shared/utils/FormattingUtils.jsx';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'monospace',
    fontSize: 16,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
  },
};

function LabelsPrintContent({ variants, quantities }) {
  return (
    <div style={styles.root}>
      {variants.map(variant => {
        if (quantities.get(variant.id, 0) > 0) {
          return (
            <div key={variant.id} style={{ marginBottom: 40 }}>
              <div style={styles.row}>
                <div style={styles.column}>
                  <div>{variant.name}</div>
                  <div>{variant.variant}</div>
                </div>
                <div style={styles.columnRight}>
                  <div>
                    {formatCurrency({ value: variant.price, fromCents: true })}
                  </div>
                </div>
              </div>
              <div style={styles.centeredRow}>{variant.sku}</div>
              <div style={styles.centeredRow}>
                <Barcode
                  value={variant.human_code}
                  height={50}
                  marginBottom={20}
                  renderer="img"
                  fontSize={18}
                />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default LabelsPrintContent;
