import debounce from 'lodash.debounce';
import { List } from 'immutable';

import Order from 'event_mgmt/shared/records/Order.jsx';
import OrderItem from 'shared/records/OrderItem';
import CreditPassPackage from 'shared/records/CreditPassPackage';

import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import POSMembershipRegistrationActions from 'point_of_sale/actions/POSMembershipRegistrationActions.jsx';
import POSProductActions from 'point_of_sale/actions/POSProductActions.js';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
// eslint-disable-next-line import/no-cycle
import POSStore from 'point_of_sale/stores/POSStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import {
  getFakeOrderItems,
  addFakeOrderItem,
  isFakeOrderItem,
  removeFakeOrderItem,
  removeFakeItemsWithoutPass,
  getFakeOrderItemsSchedules,
  clearFakeOrderItems,
} from 'shared/utils/FakeOrderItemsUtils';

import {
  CreditPassSource,
  MembershipSource,
  SessionSource,
  StaffSource,
  RegistrationSource,
} from 'sources';
import { SessionDataStore } from 'dataStores';

const url = id => `order_items/${id}`;

class POSCartStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.debouncedUpdateTaxIdAPICall = debounce(this.updateTaxIdAPICall, 500);

    this.bindListeners({
      selectClient: [
        POSActions.CLIENT_SELECTED,
        POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,
        POSClientActions.EXISTING_ACCOUNT_CONTINUE,
        POSClientActions.FETCH_MANAGING_USER_SUCCESS,
        POSClientActions.CLIENT_UNARCHIVED_SUCCESS,
      ],

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCartActions.CANCEL_CART_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      ],

      fetchCart: POSCartActions.FETCH,
      fetchCartSuccess: POSCartActions.FETCH_SUCCESS,
      fetchCartError: POSCartActions.FETCH_ERROR,

      fetchCartCreditPasses: POSCartActions.CONTINUE_TO_PAYMENT_CLICKED,
      fetchCartCreditPassesError: POSCartActions.FETCH_CART_CREDIT_PASSES_ERROR,

      addOrUpdateRegistrationPackage: POSSchedulingActions.ADD_ATTENDEE_CLICKED,
      addRegistrationPackageToCart: POSSchedulingActions.ADD_TO_CART_CLICK,
      addSubscriptionPackageToCart:
        POSMembershipRegistrationActions.ADD_TO_CART_CLICK,
      addCreditPassPackageToCart: POSCreditPassPackageActions.ADD_TO_CART_CLICK,
      addVariantToCart: POSProductActions.ADD_TO_CART_CLICKED,

      updateOrderItem: [
        POSSchedulingActions.UPDATE_CART_CLICK,
        POSMembershipRegistrationActions.UPDATE_CART_CLICK,
        POSCreditPassPackageActions.UPDATE_CART_CLICK,
        POSProductActions.UPDATE_CART_CLICKED,
      ],

      addSuccess: [
        POSCartActions.ADD_SUCCESS,
        POSCartActions.ADD_SUCCESS_NO_REDIRECT,
      ],
      addError: POSCartActions.ADD_ERROR,

      updateSuccess: [
        POSCartActions.UPDATE_SUCCESS,
        POSCartActions.UPDATE_SUCCESS_NO_REDIRECT,
      ],
      updateError: POSCartActions.UPDATE_ERROR,

      removeItem: POSCartActions.ITEM_REMOVED,
      removeSuccess: POSCartActions.REMOVE_SUCCESS,
      removeError: POSCartActions.REMOVE_ERROR,

      applyCoupon: POSCartActions.COUPON_APPLIED,
      applyCouponSuccess: POSCartActions.APPLY_COUPON_SUCCESS,
      applyCouponError: POSCartActions.APPLY_COUPON_ERROR,

      manageDiscount: POSCartActions.MANAGE_DISCOUNT,
      manageDiscountSuccess: POSCartActions.MANAGE_DISCOUNT_SUCCESS,
      manageDiscountError: POSCartActions.MANAGE_DISCOUNT_ERROR,

      toggleTaxExemption: POSCartActions.TAX_EXEMPT_TOGGLED,
      toggleFeeExemption: POSCartActions.FEE_EXEMPT_TOGGLED,
      proRateUpdate: POSCartActions.PRO_RATE_UPDATE,
      updateTaxExemptSuccess: POSCartActions.UPDATE_TAX_EXEMPT_SUCCESS,
      updateTaxExemptError: POSCartActions.UPDATE_TAX_EXEMPT_ERROR,
      updateFeeExemptSuccess: POSCartActions.UPDATE_FEE_EXEMPT_SUCCESS,
      updateFeeExemptError: POSCartActions.UPDATE_FEE_EXEMPT_ERROR,

      updateManualDiscount: POSCartActions.UPDATE_MANUAL_DISCOUNT,
      updateAccountCredits: POSCartActions.UPDATE_ACCOUNT_CREDITS,

      applyAccountCredits: POSCartActions.APPLY_ACCOUNT_CREDITS,
      applyAccountCreditsSuccess: POSCartActions.APPLY_ACCOUNT_CREDITS_SUCCESS,
      applyAccountCreditsError: POSCartActions.APPLY_ACCOUNT_CREDITS_ERROR,

      updateTaxId: POSCartActions.TAX_ID_UPDATED,
      updateProRateSuccess: POSCartActions.updateProRateSuccess,
      updateProRateError: POSCartActions.updateProRateError,

      handleFetchSessionsSuccess: POSCartActions.fetchSessionsSuccess,
      handleFetchSessionsError: POSCartActions.fetchSessionsError,

      handleFetchStaffSuccess: POSCartActions.fetchStaffSuccess,
      handleFetchStaffError: POSCartActions.fetchStaffError,

      afterCheckoutPaymentComplete: POSCheckoutActions.fetchOrderSuccess,

      addClassSchedule: POSCartActions.addClassSchedule,
      scheduleSessionSuccess: POSCartActions.scheduleSessionSuccess,
      scheduleSessionError: POSCartActions.scheduleSessionError,
    });
  }

  reset() {
    this.client = null;
    this.cart = null;
    this.cartLoaded = false;
    this.isBooking = false;
    this.total = 0;
  }

  /** Fake order items handlers BEGIN */

  // Add fake order items to the cart if they exist in the local storage
  addFakeItemsIfExist() {
    removeFakeItemsWithoutPass(
      this.cart.order_items.filter(oi => oi.isCreditPassItem())
    );

    const fakeOrderItems = getFakeOrderItems();

    if (fakeOrderItems) {
      this.cart = this.cart.set(
        'order_items',
        this.cart.order_items.concat(fakeOrderItems)
      );
    }
  }

  // Schedule fake order items after order completion
  scheduleFakeItems() {
    const fakeOrderItems = getFakeOrderItems();

    if (fakeOrderItems) {
      const schedules = getFakeOrderItemsSchedules();

      this.isBooking = true;
      RegistrationSource.schedule({
        schedules,
        success: POSCartActions.scheduleSessionSuccess,
        error: POSCartActions.scheduleSessionError,
      });
    }
  }

  // Clear fake order items from the local storage after scheduling success
  scheduleSessionSuccess() {
    clearFakeOrderItems();
    this.isBooking = false;
  }

  scheduleSessionError(...args) {
    this.notifyError('error while scheduling session', args);
    this.isBooking = false;
  }

  // Add real credit pass to cart and create fake order items for the selected sessions
  addCreditPassWithClassSchedule({
    selectedSessions,
    creditPass,
    passInCart,
    clientId,
  }) {
    const { event } = POSEventStore.getState();
    const { sessions } = SessionDataStore.getState();

    const schedules = selectedSessions.map(sessionId => ({
      client_id: clientId,
      session_id: sessionId,
      starts_at: sessions.get(sessionId).starts_at,
      ends_at: sessions.get(sessionId).ends_at,
      schedule_id: sessions.get(sessionId).schedule_id,
    }));

    const orderItem = new OrderItem({
      orderable: creditPass,
      orderable_type: 'credit_pass_package',
      quantity: creditPass.client_ids.size,
      order_id: this.cart.id,
    });

    const params = {
      url: 'order_items',
      data: JSON.stringify({
        attributes: orderItem.toServer(),
      }),
    };

    if (passInCart) {
      params.url = `order_items/${passInCart.id}`;
      uhApiClient.put({
        ...params,
        success: item => {
          addFakeOrderItem({
            schedules,
            creditPassId: creditPass.credit_pass_id,
            event,
            clientId,
          });
          POSCartActions.addSuccess.defer(item);
        },
      });
    } else {
      uhApiClient.post({
        ...params,
        success: item => {
          addFakeOrderItem({
            schedules,
            creditPassId: creditPass.credit_pass_id,
            event,
            clientId,
          });
          POSCartActions.updateSuccess.defer(item);
        },
      });
    }
  }

  // Add real registration package to cart and create fake order items for the selected sessions
  addClassSchedule({ selectedCreditPasses, selectedSessions, clientId }) {
    const orderItems = this.cart
      .get('order_items', List())
      .filter(oi => oi.isCreditPassItem());

    selectedCreditPasses.forEach(id => {
      const passInCart = orderItems.find(
        oi => oi.getIn(['orderable', 'credit_pass_id']) === id
      );

      const clientIds = passInCart
        ? [...passInCart.orderable.client_ids.toArray(), clientId]
        : [clientId];

      const cp = new CreditPassPackage({
        credit_pass_id: id,
        client_ids: clientIds,
      });

      this.addCreditPassWithClassSchedule({
        selectedSessions,
        creditPass: cp,
        passInCart,
        clientId,
      });
    });
  }

  /** Fake order items handlers END */

  afterCheckoutPaymentComplete(order) {
    this.cart = order;
    this.scheduleFakeItems();
  }

  selectClient() {
    this.waitFor(POSStore);
    this.client = POSStore.getState().selectedClient;

    if (this.client) {
      this.fetchCart();
    } else {
      this.cart = null;
      this.cartLoaded = false;
    }
  }

  fetchCart() {
    return uhApiClient.get({
      url: `/customer_users/${this.client.id}/cart`,
      data: { fields: ['coupon', 'order_items'] },
      success: POSCartActions.fetchSuccess,
      error: POSCartActions.fetchError,
    });
  }

  fetchCartSuccess(data) {
    const updatedData = data;
    updatedData.total = Number(data.total) > 0 ? data.total : 0;
    updatedData.due_immediately =
      Number(data.due_immediately) > 0 ? data.due_immediately : 0;
    this.cart = new Order(updatedData);
    this.cartLoaded = true;

    this.addFakeItemsIfExist();

    // Memberships we need to load into the data store for showing discounts.
    const membershipIds = this.cart
      .get('order_items')
      .flatMap(oi => oi.get('applied_adjustments'))
      .flatMap(aa => aa.get('details'))
      .map(
        details =>
          // need to safe nav because details is an object for coupons and an array for memberships
          details.get &&
          details.get('membership_retail_discount')?.get('membership_id')
      );

    const eventIds = this.cart.order_items
      .filter(item => item.isEventItem())
      .map(item => item.orderable?.event_id);
    const fsEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isFixedScheduleItem())
      .map(item => item.orderable?.event_id);
    const fakeEventIds = this.cart.order_items
      .filter(item => item.isFakeClassItem())
      .map(item => item.orderable.event_id);

    if (eventIds.size > 0) {
      this.handleFetchStaff({ eventIds });
      this.handleFetchSessions({
        eventIds: [...fsEventIds.toJS(), ...fakeEventIds],
      });
    } else {
      this.cartLoaded = false;
    }

    // Just need to store the memberships in the data store.
    if (membershipIds.size > 0) {
      MembershipSource.list({
        params: {
          ids: membershipIds.toJS(),
        },
      });
    }
  }

  fetchCartError(...args) {
    this.notifyError('error while fetching cart', args);
  }

  handleFetchSessions({ eventIds = [], page = 1 }) {
    if (eventIds.length === 0) return;

    this.isLoadingCart = true;
    SessionSource.list({
      params: {
        page,
        per_page: 50,
        event_ids: eventIds,
      },
      success: {
        action: POSCartActions.fetchSessionsSuccess,
        args: [eventIds],
      },
      error: POSCartActions.fetchSessionsError,
    });
  }

  handleFetchSessionsSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchSessions({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchSessionsError() {
    this.isLoadingCart = false;
  }

  handleFetchStaff({ eventIds, page = 1 }) {
    this.isLoadingCart = true;
    StaffSource.list({
      params: { page, per_page: 50, event_ids: eventIds.toJS() },
      success: {
        action: POSCartActions.fetchStaffSuccess,
        args: [eventIds],
      },
      error: POSCartActions.fetchStaffError,
    });
  }

  handleFetchStaffSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchStaff({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchStaffError() {
    this.isLoadingCart = false;
  }

  fetchCartCreditPasses() {
    const ids = this.cart.order_items
      .filter(oi => oi.orderable_type === 'credit_pass_package')
      .map(oi => oi.orderable.credit_pass_id);

    if (ids.size > 0) {
      CreditPassSource.list({
        params: {
          ids: ids.toJS(),
        },
        success: POSCartActions.fetchCartCreditPassesSuccess,
        error: POSCartActions.fetchCartCreditPassesError,
      });
    } else {
      POSActions.switchToCheckout.defer();
    }
  }

  fetchCartCreditPassesError(...args) {
    this.notifyError('error while fetching cart credit passes', args);
  }

  addOrUpdateRegistrationPackage([registrationPackage, orderItemId]) {
    if (orderItemId) {
      this.updateOrderItem([
        orderItemId,
        { orderable: registrationPackage },
        { redirect: false },
      ]);
    } else {
      this.addRegistrationPackageToCart([
        registrationPackage,
        { redirect: false },
      ]);
    }
  }

  addRegistrationPackageToCart([registrationPackage, opts]) {
    // Ideally we would be able to check the POSSchedulingStore and grab the
    // registration package from there, but the POSSchedulingStore depends on
    // the POSCartStore so that would create a circular dependency.
    this.addOrderableToCart(registrationPackage, 'registration_package', opts);
  }

  addCreditPassPackageToCart(creditPassPackage) {
    this.addOrderableToCart(creditPassPackage, 'credit_pass_package');
  }

  addSubscriptionPackageToCart(subscriptionPackage) {
    // Ideally we would be able to check the POSMembershipRegistrationStore
    // and grab the subscription package from there, but the store depends on
    // the POSCartStore so that would create a circular dependency.
    this.addOrderableToCart(
      subscriptionPackage,
      'membership_subscription_package'
    );
  }

  addVariantToCart([variantId, quantity, cashCreditDestinationId]) {
    // Ideally we would be able to check the POSProductStore and grab the variant
    // from there, but the store depends on the POSCartStore so that would create
    // a circular dependency.
    const newItem = new OrderItem({
      order_id: this.cart.id,
      orderable_id: variantId,
      orderable_type: 'variant',
      cash_credit_destination_id: cashCreditDestinationId || null,
      quantity,
    });

    this.previousCart = this.cart;
    this.cart = this.cart.addItem(newItem);
    this.isUpdatingCart = true;

    return uhApiClient.post({
      url: 'order_items',
      data: JSON.stringify({ attributes: newItem.toServer() }),
      success: POSCartActions.addSuccess,
      error: POSCartActions.addError,
    });
  }

  getOrderParams(item) {
    this.waitFor(POSEventStore);
    this.waitFor(SessionDataStore);
    const { event } = POSEventStore.getState();
    const hasTeamEvent = event.isTeamEvent();
    const { pagination } = SessionDataStore.getState();
    const orderParams = item.toServer();

    if (hasTeamEvent) {
      const sessionsCount = pagination.get('totalCount');
      orderParams.registration_package_attributes.quantity =
        sessionsCount > 0 ? sessionsCount : 1;
    }
    return orderParams;
  }

  addOrderableToCart(orderable, orderableType, opts = { redirect: true }) {
    let newItem = new OrderItem({
      order_id: this.cart.id,
      orderable,
      orderable_type: orderableType,
      quantity: 1,
    });

    this.previousCart = this.cart;

    if (orderableType === 'membership_subscription_package') {
      this.removeDuplicateSubscriptionPackages(orderable.customer_user_ids);
    }

    if (orderableType === 'credit_pass_package') {
      newItem = newItem.set('quantity', orderable.client_ids.size);
    }

    this.cart = this.cart.addItem(newItem);

    this.isUpdatingCart = true;

    const successAction = opts.redirect
      ? POSCartActions.addSuccess
      : POSCartActions.addSuccessNoRedirect;

    return uhApiClient.post({
      url: 'order_items',
      data: JSON.stringify({
        attributes: this.getOrderParams(newItem),
      }),
      success: successAction,
      error: POSCartActions.addError,
    });
  }

  addSuccess() {
    this.isUpdatingCart = false;

    this.fetchCart();
  }

  addError(...args) {
    this.isUpdatingCart = false;
    this.notifyError(
      `error while adding item to cart for client ${this.client.id}`,
      args
    );
  }

  updateManualDiscount([item, discount]) {
    const index = this.cart.order_items.findIndex(oi => oi.id === item.id);
    const updatedItem = item.merge({ manual_discount: discount }).validate();
    if (updatedItem.isMembershipItem()) {
      this.removeDuplicateSubscriptionPackages(
        updatedItem.orderable.customer_user_ids
      );
    }
    this.cart = this.cart.setIn(['order_items', index], updatedItem);
  }

  updateAccountCredits({ item, creditsAmount }) {
    const index = this.cart.order_items.findIndex(oi => oi.id === item.id);
    const updatedItem = item
      .merge({ account_credit_amount: creditsAmount })
      .validate();

    this.cart = this.cart.setIn(['order_items', index], updatedItem);
  }

  updateOrderItem([orderItemId, attributes, opts = { redirect: true }]) {
    // Ideally we would be able to check the POSSchedulingStore and grab the
    // registration package from there, but the POSSchedulingStore depends on
    // the POSCartStore so that would create a circular dependency.
    const index = this.cart.order_items.findIndex(oi => oi.id === orderItemId);
    let updatedItem = this.cart.getIn(['order_items', index]).merge(attributes);

    this.previousCart = this.cart;

    if (updatedItem.isMembershipItem()) {
      this.removeDuplicateSubscriptionPackages(
        updatedItem.orderable.customer_user_ids
      );
    }

    if (updatedItem.isCreditPassItem()) {
      updatedItem = updatedItem.set(
        'quantity',
        updatedItem.orderable.client_ids.size
      );
    }

    this.cart = this.cart.setIn(['order_items', index], updatedItem);

    this.isUpdatingCart = true;

    const successAction = opts.redirect
      ? POSCartActions.updateSuccess
      : POSCartActions.updateSuccessNoRedirect;

    return uhApiClient.put({
      url: url(orderItemId),
      data: JSON.stringify({
        attributes: this.getOrderParams(updatedItem),
      }),
      success: successAction,
      error: POSCartActions.updateError,
    });
  }

  updateSuccess() {
    this.isUpdatingCart = false;
    this.fetchCart();
  }

  updateError(...args) {
    this.isUpdatingCart = false;
    this.notifyError(`error updating cart for client ${this.client.id}`, args);
  }

  removeItem(id) {
    this.isUpdatingCart = true;
    this.previousCart = this.cart;
    this.cart = this.cart.removeItem(id);

    if (!isFakeOrderItem(id)) {
      return uhApiClient.delete({
        url: url(id),
        success: POSCartActions.removeSuccess,
        error: POSCartActions.removeError,
      });
    }

    removeFakeOrderItem(id);
    this.isUpdatingCart = false;

    return null;
  }

  removeSuccess(_data) {
    this.fetchCart();
    this.isUpdatingCart = false;
  }

  removeError(...args) {
    this.isUpdatingCart = false;
    this.notifyError(
      `error removing item from cart for client ${this.client.id}`,
      args
    );
  }

  applyCoupon({ code }) {
    this.isLoadingCoupon = true;

    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: { coupon_code: code },
        fields: ['coupon', 'order_items'],
      }),
      success: POSCartActions.applyCouponSuccess,
      error: POSCartActions.applyCouponError,
    });
  }

  applyCouponSuccess(data) {
    this.isLoadingCoupon = false;
    this.fetchCartSuccess(data);
  }

  applyCouponError(...args) {
    this.isLoadingCoupon = false;
    this.notifyError('error while fetching coupon', args);
  }

  // eslint-disable-next-line class-methods-use-this
  applyAccountCredits({ item }) {
    const attributes = {
      ...item.toServer(),
      orderable_id: item.isRetailItem()
        ? item.getIn(['orderable', 'id'])
        : null,
    };

    uhApiClient.put({
      url: `order_items/${item.id}`,
      data: JSON.stringify({
        attributes,
        fields: ['account_credit_amount'],
      }),
      success: POSCartActions.applyAccountCreditsSuccess,
      error: POSCartActions.applyAccountCreditsError,
    });
  }

  applyAccountCreditsSuccess() {
    this.fetchCart();
  }

  applyAccountCreditsError(...args) {
    this.notifyError('Error while applying account credits', args);
  }

  manageDiscount({ item, applying = false }) {
    this.isLoadingDiscount = true;
    const payload = {
      value: null,
      discount_type: null,
    };
    if (item.get('manual_discount') && applying) {
      payload.value = parseFloat(item.get('manual_discount').value, 10);
      payload.discount_type = item.get('manual_discount').type;
    }

    return uhApiClient.post({
      url: `order_items/${item.id}/discount`,
      data: JSON.stringify({ attributes: payload }),
      success: POSCartActions.manageDiscountSuccess,
      error: POSCartActions.manageDiscountError,
    });
  }

  manageDiscountSuccess() {
    this.fetchCart();
  }

  manageDiscountError(...args) {
    this.isLoadingDiscount = false;
    this.notifyError('error while managing discount', args);
  }

  toggleTaxExemption(isTaxExempt) {
    this.cart = this.cart.set('tax_exempt', isTaxExempt);

    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: this.cart.updateSensitiveFields(),
        fields: ['coupon', 'order_items'],
      }),
      success: POSCartActions.updateTaxExemptSuccess,
      error: POSCartActions.updateTaxExemptError,
    });
  }

  toggleFeeExemption(isFeeExempt) {
    this.cart = this.cart.set('fee_exempt', isFeeExempt);

    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: this.cart.updateSensitiveFieldsFee(),
        fields: ['coupon', 'order_items'],
      }),
      success: POSCartActions.updateFeeExemptSuccess,
      error: POSCartActions.updateFeeExemptError,
    });
  }

  proRateUpdate({
    prorated_memberships: proratedMemberships,
    charge_now: chargeNow,
    prorate_date: prorateDate,
  }) {
    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: this.cart.updateSensitiveFields(),
        fields: ['coupon', 'order_items'],
        prorated_memberships: proratedMemberships,
        charge_now: chargeNow,
        prorate_date: prorateDate,
      }),
      success: POSCartActions.updateProRateSuccess,
      error: POSCartActions.updateProRateError,
    });
  }

  updateProRateSuccess(data) {
    this.fetchCartSuccess(data);
    POSActions.switchToCheckout.defer();
  }

  updateProRateError(...args) {
    this.notifyError('error updating order pro rate price membership', args);
  }

  updateTaxExemptSuccess(data) {
    this.fetchCartSuccess(data);
  }

  updateFeeExemptSuccess(data) {
    this.fetchCartSuccess(data);
  }

  updateTaxExemptError(...args) {
    this.notifyError('error updating order tax exemption status', args);
  }

  updateFeeExemptError(...args) {
    this.notifyError('error updating order fee exemption status', args);
  }

  updateTaxId(value) {
    this.cart = this.cart.set('tax_id', value);
    this.debouncedUpdateTaxIdAPICall();
  }

  updateTaxIdAPICall() {
    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: this.cart.updateSensitiveFields(),
        fields: ['coupon', 'order_items'],
      }),
      success: POSCartActions.updateTaxIdSuccess,
      error: POSCartActions.updateTaxIdError,
    });
  }

  updateTaxIdSuccess(data) {
    this.fetchCartSuccess(data);
  }

  updateTaxIdError(...args) {
    this.notifyError('error updating order tax id', args);
  }

  removeDuplicateSubscriptionPackages(customerUserIds) {
    this.cart = this.cart.update('order_items', (orderItems = List()) =>
      orderItems
        .map(oi => {
          if (oi.isMembershipItem()) {
            return oi.updateIn(['orderable', 'customer_user_ids'], ids =>
              ids.subtract(customerUserIds)
            );
          }
          return oi;
        })
        .filterNot(
          oi =>
            oi.isMembershipItem() &&
            oi.getIn(['orderable', 'customer_user_ids']).size === 0
        )
    );
  }
}

export default alt.createStore(POSCartStore, 'POSCartStore');
