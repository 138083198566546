import { List, Map } from 'immutable';
import LabelPrintActions from 'retail/actions/LabelPrintActions';
import Product from 'shared/records/Product';
import RetailEditingActions from 'retail/actions/RetailEditingActions.js';
import RetailEditingStore from 'retail/stores/RetailEditingStore.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import VariantEditingActions from 'retail/actions/VariantEditingActions.js';
import VariantEditingStore from 'retail/stores/VariantEditingStore';

class LabelPrintingStore extends UpperHandStore {
  constructor() {
    super();

    this.dialogOpen = false;
    this.product = new Product();
    this.variants = List();
    this.quantities = Map();

    this.bindListeners({
      setProduct: [
        RetailEditingActions.productFetchSuccess,
        RetailEditingActions.saveOrUpdateSuccess,
      ],
      setVariants: VariantEditingActions.variantListSuccess,

      openDialog: LabelPrintActions.dialogOpened,
      closeDialog: LabelPrintActions.printAborted,

      updateQuantity: LabelPrintActions.quantityUpdated,
    });
  }

  setProduct() {
    this.waitFor(RetailEditingStore);

    this.product = RetailEditingStore.getState().product;
  }

  setVariants() {
    this.waitFor(VariantEditingStore);

    this.variants = VariantEditingStore.getState().variantMap.toList();
    this.quantities = Map().withMutations(m =>
      this.variants.forEach(v => m.set(v.id, 0))
    );
  }

  openDialog() {
    this.dialogOpen = true;
  }

  closeDialog() {
    this.dialogOpen = false;
    this.quantities = this.quantities.map(() => 0);
  }

  updateQuantity([variantId, quantity]) {
    this.quantities = this.quantities.set(variantId, Math.max(quantity, 0));
  }
}

export default alt.createStore(LabelPrintingStore, 'LabelPrintingStore');
