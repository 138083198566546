import * as React from 'react';
import ReactDOM from 'react-dom';
import { Printer } from '@bcyesil/capacitor-plugin-printer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import AltContainer from 'alt-container';
import TextField from '@mui/material/TextField';

import LabelPrintActions from 'retail/actions/LabelPrintActions';
import LabelPrintingStore from 'retail/stores/LabelPrintingStore';
import uhTheme from '_uh_theme.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { isNative } from 'shared/utils/UserAgentUtils.js';
import LabelsPrintContent from './LabelsPrintContent.jsx';

const rowStyle = index => ({
  backgroundColor: index % 2 === 0 ? 'white' : '#EFEFEF',
  color: uhTheme.palette.textColor,
});

function QuantityTable({ variants, quantities }) {
  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <td style={{ paddingLeft: 24 }}>
            <FormattedMessage id={messageId('.variant', __filenamespace)} />
          </td>
          <td>
            <FormattedMessage id={messageId('.sku', __filenamespace)} />
          </td>
          <td style={{ paddingRight: 24 }}>
            <FormattedMessage id={messageId('.qty', __filenamespace)} />
          </td>
        </tr>
      </thead>
      <tbody>
        {variants
          .map((variant, index) => (
            <tr key={variant.id} style={rowStyle(index)}>
              <td style={{ paddingLeft: 24 }}>
                {variant.option_values.map(v => v.value).join(' · ')}
              </td>
              <td>{variant.sku || '-'}</td>
              <td style={{ width: '3em', paddingRight: 24 }}>
                <TextField
                  variant="standard"
                  autoFocus={index === 0}
                  name={`qty-${variant.id}`}
                  type="number"
                  value={quantities.get(variant.id, 0)}
                  style={{ width: '3em', height: 35 }}
                  onChange={e =>
                    LabelPrintActions.quantityUpdated(
                      variant.id,
                      e.target.value
                    )
                  }
                />
              </td>
            </tr>
          ))
          .toList()}
      </tbody>
    </table>
  );
}

const print = (variants, quantities) => {
  const div = document.createElement('div');
  div.classList.add('printable-area');
  document.body.appendChild(div);

  const handlePrint = () => {
    document.body.classList.add('printing');
    if (isNative()) {
      setTimeout(() => Printer.print({ content: div.innerHTML }), 100);
    } else {
      setTimeout(window.print, 100);
    }

    window.onafterprint = () => {
      document.body.classList.remove('printing');
      document.body.removeChild(div);
      LabelPrintActions.printAborted();
      window.onafterprint = null;
    };
  };

  ReactDOM.render(
    <LabelsPrintContent variants={variants} quantities={quantities} />,
    div,
    handlePrint
  );
};

function Content({ labelStore: { dialogOpen, variants, quantities }, intl }) {
  return (
    <Confirmation
      classes={{
        root: 'print-label-dialog',
        title: 'dialog-title',
        actions: 'dialog-actions',
        content: 'dialog-content',
      }}
      hasCheckbox={false}
      open={dialogOpen}
      title={t('.print_labels', intl, __filenamespace)}
      onCancel={LabelPrintActions.printAborted}
      onConfirm={() => {
        print(variants, quantities);
      }}
      cancelButtonLabel={t('actions.cancel', intl, __filenamespace)}
      confirmButtonLabel={t('.print', intl, __filenamespace)}
    >
      <QuantityTable variants={variants} quantities={quantities} />
    </Confirmation>
  );
}

function LabelPrintDialog(props) {
  return (
    <AltContainer stores={{ labelStore: LabelPrintingStore }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Content {...props} />
    </AltContainer>
  );
}

export default injectIntl(LabelPrintDialog);
