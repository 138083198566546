import debounce from 'lodash.debounce';
import { List, Map } from 'immutable';

import CustomerUser from 'shared/records/Customer.jsx';
import DateRange, { THIS_YEAR } from 'shared/records/DateRange';
import OrderHistoryActions from 'retail/actions/OrderHistoryActions';
import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';
import PurchaseOrder from 'retail/records/PurchaseOrder';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const PER_PAGE = 10;

class OrderHistoryStore extends UpperHandStore {
  constructor() {
    super();

    this.pagination = Map({ page: 1, per_page: PER_PAGE });
    this.purchaseOrders = List();
    this.customerUsers = Map();
    this.total = 0;
    this.search = '';
    this.dateFilter = new DateRange({ value: THIS_YEAR });
    this.isLoading = false;

    this.debouncedList = debounce(this.listPurchaseOrders, 400);

    this.bindListeners({
      listCustomerUsers: OrderHistoryActions.LIST_CUSTOMER_USERS,
      listCustomerUsersSuccess: OrderHistoryActions.LIST_CUSTOMER_USERS_SUCCESS,
      listCustomerUsersError: OrderHistoryActions.LIST_CUSTOMER_USERS_ERROR,
      listPurchaseOrders: [
        OrderHistoryActions.PURCHASE_ORDER_LIST,
        InventoryDialogActions.UPDATE_PURCHASE_ORDER_SUCCESS,
      ],
      listPurchaseOrdersSuccess:
        OrderHistoryActions.PURCHASE_ORDER_LIST_SUCCESS,
      listPurchaseOrdersError: OrderHistoryActions.PURCHASE_ORDER_LIST_ERROR,
      pageChanged: OrderHistoryActions.PAGE_CHANGED,
      updateDateFilter: OrderHistoryActions.UPDATE_DATE_FILTER,
      handleSearch: OrderHistoryActions.SEARCH,
    });
  }

  listPurchaseOrders() {
    const { startTime, endTime } = this.dateFilter;
    this.isLoading = true;
    const payload = {
      ...this.pagination.toJS(),
      search: this.search,
      start_date: startTime.toISOString(),
      end_date: endTime.toISOString(),
      fields: ['total_quantity'],
    };
    uhApiClient.get({
      url: 'purchase_orders',
      data: payload,
      success: OrderHistoryActions.purchaseOrderListSuccess,
      error: OrderHistoryActions.listPurchaseOrdersError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  listCustomerUsers(ids) {
    uhApiClient.get({
      url: 'customer_users',
      data: {
        ids: [...new Set(ids)],
        types: ['staff_member', 'staff_admin', 'admin'],
        per_page: 100,
      },
      success: OrderHistoryActions.listCustomerUsersSuccess,
      error: OrderHistoryActions.listCustomerUsersError,
    });
  }

  listCustomerUsersSuccess(data) {
    this.isLoading = false;
    this.customerUsers = Map(
      data.customer_users.map(item => [item.id, new CustomerUser(item)])
    );
  }

  listCustomerUsersError(...args) {
    this.isLoading = false;
    this.notifyError('error fetching customer users', ...args);
  }

  listPurchaseOrdersSuccess({
    page,
    total_count: total,
    purchase_orders: purchaseOrders,
  }) {
    this.listCustomerUsers(
      purchaseOrders.map(purchaseOrder => purchaseOrder.created_by_id)
    );
    this.pagination = Map({ page, per_page: PER_PAGE });
    this.total = total;
    this.purchaseOrders = List(purchaseOrders).map(po => new PurchaseOrder(po));
  }

  listPurchaseOrdersError(...args) {
    this.isLoading = false;
    this.notifyError('error fetching purchase orders', args);
  }

  pageChanged(page) {
    this.pagination = Map({ page, per_page: PER_PAGE });
    this.listPurchaseOrders();
  }

  updateDateFilter(dateParams) {
    this.dateFilter = new DateRange(dateParams);
    this.debouncedList();
  }

  handleSearch(search) {
    this.search = search;
    this.debouncedList();
  }
}

export default alt.createStore(OrderHistoryStore, 'OrderHistoryStore');
