import { List, Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import OptionValue from 'shared/records/OptionValue';
import ProductImage from 'shared/records/ProductImage';

import { isPresent, merge } from 'shared/utils/ObjectUtils.jsx';
import { isValidGTIN } from 'shared/utils/GTINUtils';

const READONLY_FIELDS = ['option_value_ids', 'option_values'];

class Variant extends Record({
  id: null,
  uuid: null,
  cost: 0,
  name: '',
  variant: '',
  human_code: '',
  image: new ProductImage(),
  option_value_ids: Set(),
  price: 0,
  product_id: null,
  product_image_ids: null,
  sku: null,
  live_qty: 0,
  gtin: null,
  errors: new FieldErrors(),
  qty_on_hand: 0,
  trigger_qty: null,
  option_values: List(),
}) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        image: new ProductImage(obj.image || {}),
        option_value_ids: Set(obj.option_value_ids),
        option_values: List(
          (obj.option_values || []).map(v => new OptionValue(v))
        ),
        product_image_ids: Set(obj.product_image_ids),
      })
    );
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();

    ['price', 'cost'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });

    if (isPresent(this.gtin) && !isValidGTIN(this.gtin)) {
      errors = errors.add('gtin', 'records.Variant.errors.gtin.invalid');
    }

    return this.set('errors', errors);
  }

  toServer() {
    const serverValue = this.toJS();
    READONLY_FIELDS.forEach(field => delete serverValue[field]);

    if (serverValue.sku === '') {
      serverValue.sku = null;
    }

    if (serverValue.trigger_qty === null) {
      delete serverValue.trigger_qty;
    }

    if (serverValue.gtin === '') {
      serverValue.gtin = null;
    }

    return serverValue;
  }
}

export default Variant;
